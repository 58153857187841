import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem } from '../../utils/Tools';
import { StyledStackedCircles } from '../StackedCircles/StackedCircles.styled';

export const StyledBlogPost = styled.div`
  padding: ${pxtorem(80)} 0 ${pxtorem(120)};

  ${Wrapper} {
    position: relative;
  }

  .intro {
    text-align: center;
    margin-bottom: max(5.55vw, 40px);
    @media (min-width: 1440px) {
      margin-bottom: 80px;
    }
  }

  .title {
    letter-spacing: -0.05em;
    font: 600 max(6.388vw, 40px)/max(7.777vw,48px) var(--primaryFont);
    @media (min-width: 1440px) {
      font: 600 92px/112px var(--primaryFont);
    }

    margin: 0;
  }

  .subtitle {
    display: block;
    margin-top: 15px;
    font: 400 12px/20px var(--primaryFont);
  }
  
  .circle1 {
    position: absolute;
    z-index: 0;
    left: 100%;
    top: 0;
  }

  .circle2 {
    position: absolute;
    z-index: 0;
    right: 100%;
    top: calc(100vh);
  }

  @media (max-width: 768px) {
    padding: 40px 0 64px;

    .intro {
      margin-bottom: 40px;
    }

    ${StyledStackedCircles} {
      --radius: 150px;
    }

    .circle1 {
      left: calc(100% - 50px);
      top: -101px;
    }

    .circle2 {
      right: calc(100% - 20px);
      top: 30px;
    }
  }

`