import React from 'react';

import { Cta } from '../components/Cta/Cta';
import { PactImage } from '../components/PactImage/PactImage';
import ShowOnScroll from '../components/ShowOnScroll/ShowOnScroll';

const wrapWithShowScroll = (props: any, replaceTagWith?: string) => {
  const Tag = replaceTagWith ? replaceTagWith : props.node.tagName;
  return <ShowOnScroll><Tag>{props.children}</Tag></ShowOnScroll>
}

export const CMS_SHORTCODES = {
  h1: 'h2',
  cta: (props: { href: string, children: string }) => {
    return <Cta href={props.href} children={props.children} />
  },
  p: (props: any) => {
    let Tag: any = 'p';
    if (props.children[0]?.props?.src) {
      Tag = 'div';
    }
    return <Tag>{props.children}</Tag>
  }
}