import { MDXProvider } from '@mdx-js/react';
import React, { useEffect } from 'react';
import { CMS_SHORTCODES } from '../../cms/cms-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { RenderMarkdown } from '../core';
import { StyledArticle } from './Article.styled';

export const Article: React.FC = ({ children }) => {
  //#region Hooks / Lifecycles
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  return (
    <StyledArticle>
      {children}
    </StyledArticle>
  )
  //#endregion
}

export default Article;