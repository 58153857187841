import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/Tools';

export const StyledArticle = styled.article`
  color: rgba(249,247,238,0.75); 
  --gap: ${pxtorem(40)};
  p {
    margin-top: 0;
    margin-bottom: var(--gap);
    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not([class*="cta--"]) {
    --color: var(--pink);
    transition: color .25s;
    text-decoration: underline;
    ${desktopHover(`
      --color: var(--cream);
    `)}
  }

  .gatsby-resp-image-image.gatsby-resp-image-image {
    box-shadow: none;
  }

  h1,h2,h3,h4,h5 {
    color: var(--color);
  }

  ul, ol, h3, h4, h5 {
    margin-bottom: var(--gap);
  }

  h1, h2 {
    letter-spacing: -0.05em;
    /* font: 600 max(3.61vw, 32px)/max(4.375vw,40px) var(--primaryFont); */
    font: 600 52px/63px var(--primaryFont);
    @media (min-width: 1440px) {
    }
    
    margin-bottom: var(--gap);
    &:not(:first-child) {
      margin-top: ${pxtorem(80)};
    }
  }

  .previewImg {
    max-width: 100%;
  }

  .article-image {
    margin-top: ${pxtorem(80)};
    margin-bottom: var(--gap);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      text-align: center;
      color: var(--cream);
      margin-top: 32px;
      font: 300 12px/20px var(--secondaryFont);
    }
  }

  blockquote {
    margin: 0;
    padding: 0;
    margin-bottom: var(--gap);

    font: 400 1rem/${pxtorem(24)} var(--primaryFont);
    letter-spacing: -0.05em;
    font-style: italic;
    text-align: center;
  }

  li {
    margin-bottom: calc(var(--gap) / 3);
  }
  
  ol {
    counter-reset: olCounter;

    li {
      display: block;
      counter-increment: olCounter;
      &:before {
        content: counter(olCounter)'.';
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  ul {
    li {
      position: relative;
      display: block;
      padding-left: 15px;
      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background: var(--pink);
        border-radius: 100%;
        top: 12px;
        left: 0;
      }
    }
  }

  table {
    border-collapse: collapse;
    font-size: .8em;
    margin-bottom: var(--gap);

    th, td {
      border: 1px solid rgba(249, 247, 238, 0.5);
      padding: 5px 15px;
    }

    tr:nth-child(even) td{
      background: rgba(249, 247, 238, 0.02);
    }
  }

  @media (max-width: 768px) {
    --gap:  24px;
    font-size: 16px;
    line-height: 26px;
    
    .article-image {
      margin-top: 40px;
    }

    h1, h2 {
      font-size: 32px;
      line-height: 40px;
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
`