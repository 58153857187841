import React from 'react';
import { graphql } from 'gatsby';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { BlogPost, BlogPostData } from '../components/BlogPost/BlogPost';
import { Layout } from '../components/Layout/Layout';
import theme from '../styles/theme';

const Blog: React.FC<MarkdownData<BlogPostData>> = (props) => {
  return (
    <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
      <ThemeProvider theme={theme}>
        <Layout>
          <BlogPost {...props} />
        </Layout>
      </ThemeProvider>
    </StyleSheetManager>
  )
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        slug
        blog_content
        title
        subtitle
        seoTitle
        seoDescription
        seoImage {
          publicURL
        }
        linkedFiles {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    }
    seo: markdownRemark(fileAbsolutePath: {regex: "/seo.md/"}) {
      frontmatter {
        image {
          publicURL
        }
      }
    }
  }
`

export default Blog;