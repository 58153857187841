import { spawn } from 'child_process';
import React, { useEffect, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { ImageDataLike } from 'gatsby-plugin-image';
import { CMS_SHORTCODES } from '../../cms/cms-components';
import { Section } from '../../styles/Section.style';
import { Wrapper } from '../../styles/Wrapper.style';
import Article from '../Article/Article';
import { Cta } from '../Cta/Cta';
import { PactImage } from '../PactImage/PactImage';
import { StaticSEO } from '../SEO';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StackedCircles } from '../StackedCircles/StackedCircles';
import { StyledBlogPost } from './BlogPost.styled';

export const BlogPost: React.FC<MarkdownDataWithSEO<BlogPostData>> = ({ data }) => {
  //#region Hooks / Lifecycles
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { title, subtitle, blog_content, isPreview, linkedFiles, seoTitle, seoDescription, seoImage } = data.markdownRemark?.frontmatter || {};
  const { image: defaultSeoImage } = data.seo?.frontmatter || {};

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  const imageTag = useCallback((props: any) => {
    const matchingImage = (linkedFiles || []).find(image => image.relativePath === `${props.src}`.split('/').pop())

    return (
      <div className="article-image">
        <PactImage image={matchingImage || props.src} addMaxWidth alt="" />
        {props.title && <p className="article-image-title">{props.title}</p>}
      </div>
    )
  }, [linkedFiles])

  //#region Templating
  return (
    <StyledBlogPost>
      <StaticSEO
        title={`${seoTitle || title} - Chord`}
        description={seoDescription}
        image={{ publicURL: seoImage ? seoImage.publicURL : defaultSeoImage?.publicURL }} />
      <Section bg="charcoal">
        <Wrapper width={874}>
          {!isPreview &&
            <>
              <div className="circle1">
                <StackedCircles waitUntilVisible={true} rotate={{ x: 34, y: -36, z: 0 }} />
              </div>
              <div className="circle2">
                <StackedCircles waitUntilVisible={true} controlledByScroll={true} rotate={{ x: 34, y: -36, z: 0 }} />
              </div>
            </>
          }
          <div className="intro">
            <ShowOnScroll>
              <h1 className="title">{title}</h1>
              {subtitle && <span className="subtitle">{subtitle}</span>}
            </ShowOnScroll>
          </div>
          <ShowOnScroll delay={125}>
            <Article>
              <ReactMarkdown
                components={{
                  ...CMS_SHORTCODES,
                  img: imageTag
                }}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[gfm]}
                transformImageUri={(src: string) => isPreview ? src.split('../static/').join('/') : src}>
                {blog_content}
              </ReactMarkdown>
            </Article>
          </ShowOnScroll>
        </Wrapper>
      </Section>
    </StyledBlogPost>
  )
  //#endregion
}

export const BlogPostPreview: React.FC<BlogPostData> = (data: BlogPostData) => {
  return (
    <BlogPost data={{
      markdownRemark: {
        frontmatter: { ...{ isPreview: true }, ...data }
      }
    }} />
  )
}

export interface BlogPostData {
  title: string;
  subtitle: string;
  blog_content?: string;
  isPreview?: boolean;
  seoTitle: string;
  seoDescription: string;
  seoImage: {
    publicURL: string;
  };
  linkedFiles?: any[]
}